.form__save-button {
  height: 50px;
  width: 100%;
  background-color: #fff;
  color: #000;
  font-size: 18px;
  font-weight: normal;
  line-height: 1.2;
  border: none;
  opacity: 1;
  transition: opacity 0.1s ease-out .1s, color 0.5s ease-in-out, background-color 0.5s ease-in-out;
}

.form__save-button:hover {
  opacity: 0.8;
  cursor: pointer;
}
