.profile__description {
  max-width: 375px;
  color: #fff;
  grid-area: description;
  -ms-grid-column-span: 2;
  grid-column: span 2;
  margin: 0;
  font-size: 18px;
  line-height: 1.2;
  font-weight: 400;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

@media screen and (max-width: 652px) {
  .profile__description {
    font-size: 14px;
  }
}
