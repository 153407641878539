.popup__save-button {
  height: 50px;
  width: 100%;
  padding: 0;
  color: #fff;
  background-color: #000;
  font-size: 18px;
  font-weight: normal;
  line-height: 1.2;
  border: none;
  border-radius: 2px;
  opacity: 1;
  -webkit-transition: opacity 0.1s ease-out .1s, color 0.1s ease-out .1s, background-color 0.1s ease-out .1s;
  -o-transition: opacity 0.1s ease-out .1s, color 0.1s ease-out .1s, background-color 0.1s ease-out .1s;
  transition: opacity 0.1s ease-out .1s, color 0.5s ease-in-out, background-color 0.5s ease-in-out;
}

.popup__save-button:hover {
  opacity: 0.8;
  cursor: pointer;
}

@media screen and (max-width: 652px) {
  .popup__save-button {
    font-size: 14px;
  }
}
