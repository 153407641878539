.photo__title {
  width: 225px;
  margin: 0 auto;
  font-size: 24px;
  font-weight: 900;
  line-height: 1.2;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
