.footer {
  width: 880px;
  margin: auto auto 0 auto;
  padding-bottom: 60px;
}

@media screen and (max-width: 932px) {
  .footer {
    width: 581px;
  }
}

@media screen and (max-width: 652px) {
  .footer {
    width: 282px;
    padding-bottom: 36px;
  }
}
