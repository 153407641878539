.photo__image {
  width: 282px;
  height: 282px;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
}

.photo__image:hover {
  cursor: pointer;
}
