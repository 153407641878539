.header__button {
  margin-left: 24px;
  opacity: 1;
  transition: opacity 0.1s ease-out .1s, color 0.5s ease-in-out, background-color 0.5s ease-in-out;
}

.header__button:hover {
  cursor: pointer;
  opacity: 0.8;
}

@media screen and (max-width: 652px) {
  .header__button {
    margin: 18px 0 0 0;
  }
}
