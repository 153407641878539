.profile__add-button {
  background-image: url(../../../images/add-button.svg);
  background-color: rgba(0,0,0,0);
  background-size: 22px 22px;
  background-repeat: no-repeat;
  background-position: center;
  padding: 0;
  width: 150px;
  height: 50px;
  border: 2px solid #fff;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  opacity: 1;
  -webkit-transition: opacity 0.1s ease-out .1s;
  -o-transition: opacity 0.1s ease-out .1s;
  transition: opacity 0.1s ease-out .1s;
}

.profile__add-button:hover {
  opacity: 0.6;
  cursor: pointer;
}



@media screen and (max-width: 652px) {
  .profile__add-button {
    width: 282px;
  }
}
