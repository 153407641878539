.popup__image-title {
  margin: 0;
  font-size: 12px;
  line-height: 1.2;
  font-weight: 400;
  color: #fff;
  position: absolute;
  left: 0;
  bottom: -22px;
}
