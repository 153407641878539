.page {
  background-color: #000;
  font-family: 'Inter', sans-serif;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0 auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  min-height: 100vh;
  min-width: 320px;
  display: flex;
  flex-direction: column;
}
