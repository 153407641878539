@keyframes spinner-circle {
  0% { transform: rotate(-180deg); }
  100% { transform: rotate(180deg); }
}

.spinner {
  width: 1em;
  height: 1em;
  margin: 50px auto;
  border-radius: 50%;
  display: block;
}

.spinner i::after {
  position: absolute;
  clip: rect(0, 1em, 1em, 0.5em);
  width: 1em;
  height: 1em;
  content: "";
  animation: spinner-circle 0.8s ease-in-out infinite;
  border-radius: 50%;
  box-shadow: inset 0 0 0 0.1em #fff;
}
