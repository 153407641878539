.header__menu-icon {
  height: 21px;
  width: 24px;
  padding-top: 5px;
  opacity: 1;
  transition: opacity 0.1s ease-out .1s;
}

.header__menu-icon:hover {
  opacity: 0.6;
  cursor: pointer;
}
