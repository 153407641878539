.popup__save-button_disabled {
  border: 1px solid #000;
  color: #000;
  opacity: 0.2;
  background-color: #fff;
}

.popup__save-button_disabled:hover {
  opacity: 0.2;
  cursor: auto;
}
