.register__login-text-button {
  width: 100%;
  margin: 15px 0 0 0;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.2;
  text-align: center;
  opacity: 1;
  transition: opacity 0.1s ease-out .1s, color 0.5s ease-in-out, background-color 0.5s ease-in-out;
}

.register__login-text-button:hover {
  opacity: 0.8;
  cursor: pointer;
}
