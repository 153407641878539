.popup {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: visibility 0.6s ease-in-out, opacity 0.6s ease-in-out, pointer-events 0.6s ease-in-out;
  -o-transition: visibility 0.6s ease-in-out, opacity 0.6s ease-in-out, pointer-events 0.6s ease-in-out;
  transition: visibility 0.6s ease-in-out, opacity 0.6s ease-in-out, pointer-events 0.6s ease-in-out;
  background-color: rgba(0,0,0,0.5);
  content: ' ';
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 15;
}
