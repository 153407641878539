.popup__title {
  margin: 0 0 54px 0;
  font-weight: 900;
  font-size: 24px;
  line-height: 1.2;
}

@media screen and (max-width: 652px) {
  .popup__title {
    margin: 0 0 76px 0;
    font-size: 18px;
  }
}
