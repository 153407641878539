.form__error {
  display: inline-block;
  color: #f00;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.25;
  min-height: 20px;
  min-width: 15px;
  margin: 5px 0 10px 0;
}

.form__error:last-child {
  margin-top: 145px;
}
