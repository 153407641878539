.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  border-bottom: 1px solid rgba(84, 84, 84, 0.7);
  width: 880px;
  max-width: 880px;
  margin: 0 auto;
  padding-top: 45px;
}

@media screen and (max-width: 932px) {
  .header {
    max-width: 581px;
  }
}

@media screen and (max-width: 652px) {
  .header {
    flex-direction: column-reverse;
    align-items: stretch;
    max-width: 320px;
    padding-top: 28px;
  }
}
