.header__text {
  color: #fff;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.2;
  margin: 5px 0 46px 0;
}

@media screen and (max-width: 652px) {
  .header__text {
    min-width: 50px;
    text-align: center;
    font-weight: 500;
    line-height: 1.2;
    border-bottom: 1px solid rgba(84, 84, 84, 0.7);
    display: flex;
    flex-direction: column;
    padding-bottom: 40px;
    margin: 0;
  }
}
