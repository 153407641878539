.profile__pic {
  position: relative;
  border-radius: 50%;
  height: 120px;
  width: 120px;
  z-index: -1;
  background-position: center;
  background-size: cover;
}

.profile__pic:hover {
  cursor: pointer;
}
