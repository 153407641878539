.form__input {
  color: #fff;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.2;
  padding: 0 0 10px 0;
  background-color: transparent;
  border: none;
  border-bottom: 2px solid #ccc;
}

.form__input:focus {
  outline: none;
}
