.photo__like {
  opacity: 1;
  background-image: url(../../../images/like-button.svg);
  background-color: rgba(0,0,0,0);
  background-size: cover;
  border: none;
  padding: 0;
  width: 22px;
  height: 20px;
  -webkit-transition: opacity 0.1s ease-out .1s;
  -o-transition: opacity 0.1s ease-out .1s;
  transition: opacity 0.1s ease-out .1s;
}

.photo__like:hover {
  opacity: 0.5;
  cursor: pointer;
}
