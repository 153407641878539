.profile__edit-pic {
  position: relative;
  background-image: url(../../../images/edit-pic.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  border: none;
  padding: 0;
  height: 120px;
  width: 120px;
  z-index: -2;
  transition: background-color .1s linear;
}

.profile__edit-pic:hover {
  cursor: pointer;
  z-index: unset;
  background-color: rgba(0, 0, 0, 0.8);
}

@media screen and (max-width: 652px) {
  .profile__edit-pic {
    margin-bottom: 30px;
  }
}
