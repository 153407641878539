.profile {
  position: relative;
  z-index: 10;
  max-width: 880px;
  margin: 0 auto;
  padding-top: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@media screen and (max-width: 932px) {
  .profile {
    max-width: 581px;
    padding-top: 42px;
  }
}

@media screen and (max-width: 652px) {
  .profile {
    max-width: 282px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    align-items: center;
  }
}
